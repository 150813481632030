<template>
  <v-container fluid>
    <v-toolbar flat class="mb-2">
      <v-autocomplete
        v-model="selectedLevel"
        :items="levels"
        clearable
        label="Stockwerk"
        hide-details
        class="mr-2"
        item-value="value"
      >
      </v-autocomplete>

      <SchoolClassInput
        label="Klasse wählen"
        hide-details
        clearable
        v-model="schoolClass"
      />

      <v-text-field
        class="mx-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Name, Klasse oder Nummer"
        clearable
        single-line
        hide-details
      ></v-text-field>

      <v-btn-toggle dense v-model="selectedOccupation" mandatory>
        <v-btn
          v-for="item in occupation"
          :key="item.text"
          text
          color="primary"
          :value="item.value"
        >
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>

    <v-card>
      <v-data-table
        width="300"
        :loading="loading"
        :headers="headers"
        :items="filteredItems"
        :items-per-page="20"
        disable-sort
        :item-class="() => 'clickable'"
        @click:row="(item, row) => showDetails(item.id, row.index)"
      >
        <template v-slot:item.code="{ item }">
          <v-chip>{{ item.code }}</v-chip>
        </template>
        <template v-slot:item.students[0]="{ item }">
          <PersonItem :value="item.students[0]" />
        </template>
        <template v-slot:item.students[1]="{ item }">
          <PersonItem
            :value="item.students[1]"
            v-if="item.students[1] || item.size >= 2"
          />
        </template>
        <template v-slot:item.students[2]="{ item }">
          <PersonItem
            :value="item.students[2]"
            v-if="item.students[2] || item.size >= 3"
          />
        </template>

        <template v-slot:item.icons="{ item }">
          <v-tooltip v-if="item.comment" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="info" v-bind="attrs" v-on="on">
                mdi-information</v-icon
              >
            </template>
            {{ item.comment }}
          </v-tooltip>
        </template>

        <template v-slot:item.size="{ item }">
          {{ item.students.length }}/{{ item.size }}
        </template>
      </v-data-table>
    </v-card>

    <router-view @update="fetchData" @next="next" @prev="prev"></router-view>
  </v-container>
</template>

<script>
import PersonItem from "@/components/PersonItem";
import SchoolClassInput from "@/components/SchoolClassInput";

import { searchPerson } from "common/utils/people.js";

export default {
  name: "Locker",
  components: {
    PersonItem,
    SchoolClassInput,
  },
  data: () => ({
    loading: false,
    index: 0,
    items: [],
    schoolClass: null,
    search: "",
    selectedLevel: "",
    selectedOccupation: 0,
    levels: [
      { text: "Geschoss 00", value: "0" },
      { text: "Geschoss 01", value: "1" },
      { text: "Geschoss 02", value: "2" },
      { text: "Geschoss 03", value: "3" },
      { text: "Geschoss 04", value: "4" },
      { text: "Pavillon", value: "P" },
    ],
    occupation: [
      { text: "alle", value: "all" },
      { text: "frei", value: "free" },
      { text: "leer", value: "empty" },
    ],
    headers: [
      {
        text: "Schrank",
        value: "code",
      },
      {
        text: "Schüler*in 1",
        value: "students[0]",
      },

      {
        text: "Schüler*in 2",
        value: "students[1]",
      },
      {
        text: "Schüler*in 3",
        value: "students[2]",
      },
      {
        text: "Plätze",
        value: "size",
      },
      {
        text: "",
        value: "icons",
      },
    ],
  }),
  methods: {
    goto(index) {
      const id = this.filteredItems[index]
        ? this.filteredItems[index].id
        : false;
      if (id) {
        this.showDetails(id, index);
      }
    },
    prev() {
      this.goto(this.index - 1);
    },
    next() {
      this.goto(this.index + 1);
    },
    async fetchData() {
      this.loading = true;
      const data = await this.apiList({
        resource: "locker/locker",
      });
      this.items = data.sort((a, b) => a.code.localeCompare(b.code));
      this.loading = false;
    },
    showDetails(id, index) {
      this.index = index;
      this.$router.push({ name: "LockerDetails", params: { id: id } });
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) =>
          (this.selectedOccupation == "all" ||
            (this.selectedOccupation == "free" &&
              (item.students.length >= 1 ||
                (item.students.length == 2 && !item.small))) ||
            (this.selectedOccupation == "empty" &&
              item.students.length == 0)) &&
          (!this.selectedLevel || item.code.startsWith(this.selectedLevel)) &&
          (!this.schoolClass ||
            item.students.some((student) =>
              searchPerson(student, this.schoolClass.code)
            )) &&
          (!this.search ||
            item.code.toLowerCase().includes(this.search.toLowerCase()) ||
            item.students.some((student) => searchPerson(student, this.search)))
      );
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
